// outsource dependencies
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as appIcons from '../assets/svg-icons';

// NOTE custom icons which we inject in font awesome
library.add(appIcons);

// NOTE production connected icons make sure in the repo wos not present development (unused) icons
library.add(fas);
library.add(far);
library.add(fab);

export default FontAwesomeIcon;

const defaultProps = {};
const propTypes = {
  icon: PropTypes.string.isRequired
};

export const FasIcon = memo(({ icon, ...attr }) => <FontAwesomeIcon
  icon={['fas', icon]}
  {...attr}
/>);
FasIcon.defaultProps = defaultProps;
FasIcon.propTypes = propTypes;

export const FarIcon = memo(({ icon, ...attr }) => <FontAwesomeIcon
  icon={['far', icon]}
  {...attr}
/>);
FarIcon.defaultProps = defaultProps;
FarIcon.propTypes = propTypes;

export const FabIcon = memo(({ icon, ...attr }) => <FontAwesomeIcon
  icon={['fab', icon]}
  {...attr}
/>);
FabIcon.defaultProps = defaultProps;
FabIcon.propTypes = propTypes;

export const AppIcon = memo(({ icon, ...attr }) => <FontAwesomeIcon
  icon={['app', icon]}
  {...attr}
/>);
AppIcon.defaultProps = defaultProps;
AppIcon.propTypes = propTypes;
