// outsource dependencies
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { memo, useState, useCallback } from 'react';

import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';

// local dependencies
import { FasIcon } from './fa-icon';

const AlertError = memo(({ error }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  return <Alert
    showIcon
    type="error"
    message={error?.message || t('errors.default')}
    icon={<FasIcon icon="exclamation-triangle" />}
    description={isOpen && <pre className="mt-2">
      {JSON.stringify(error, null, 2)}
    </pre>}
    action={
      <Button size="small" danger onClick={toggleOpen}>
        { t('errors.details') }
      </Button>
    }
  />;
});
AlertError.propTypes = {
  error: PropTypes.object.isRequired,
};

export default AlertError;
