// outsource dependencies
import notification from 'antd/lib/notification';

export const NOTIFICATION_TYPE = {
  warning: 'warning',
  success: 'success',
  error: 'error',
  info: 'info',
};

const NotificationService = (type, data) => notification[type](data);

export default NotificationService;
