// outsource dependencies
import * as Survey from 'survey-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import React, { memo, useCallback, useState } from 'react';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

// We need it for rating
import $ from 'jquery';
import * as widgets from 'surveyjs-widgets';
import 'jquery-bar-rating/jquery.barrating.js';

// Define Survey JSON
import config from '../../configs/app-config';
import AlertError from '../../components/alert-error';
import { BoxLoader } from '../../components/preloader';
import NotificationService, { NOTIFICATION_TYPE } from '../../services/notification';

import instagram from '../../assets/icons/instagram.svg';

import { SaveResult } from '../../apollo/api/results/saveResult.graphql';
import { GetQuestionnaire } from '../../apollo/api/questionnaires/getQuestionnaire.graphql';

import logo from '../../assets/logo/logo_full.png';

// NOTE Configure (We need it for rating)
window.$ = $;
window.jQuery = $;
widgets.jquerybarrating(Survey);

Survey
  .StylesManager
  .applyTheme('bootstrap');

Survey.defaultBootstrapCss.navigationButton = 'btn btn-success';

export default memo(() => {
  const { id } = useParams();
  const [survey, setSurvey] = useState(null);
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(GetQuestionnaire, {
    variables: {
      id,
    },
    onCompleted: ({ questionnaire }) => {
      const survey = new Survey.Model({
        completedHtml: `<div>
          <h1 class="text-center font-weight-bold mb-30">${t('result.title')}</h1>
          <a class="d-block text-center mb-10 text-black" target="_blank" href="${config.REACT_APP_INSTAGRAM}" rel="noopener noreferrer">
            <img width="42" src="${instagram}" alt="Instagram abiagenten" class="mb-10">
            <span class="d-block">${t('result.link')}</span>
          </a>
          <p class="text-center">${t('result.thanks')}</p>
        </div>`,
        ...questionnaire?.template?.template,
      });
      survey.showQuestionNumbers = 'off';
      survey.data = questionnaire?.results?.[0]?.result;
      setSurvey(survey);
    },
    onError: (error) => {
      NotificationService(NOTIFICATION_TYPE.error, {
        message: t('errors.default'),
        description: error.message,
      });
    }
  });

  const [saveResult] = useMutation(SaveResult);

  const onComplete = useCallback(async (survey, options) => {
    try {
      await saveResult({
        variables: {
          input: {
            questionnaireId: id,
            id: data?.questionnaire?.results?.[0]?.id,
            result: {
              ...survey.data
            },
          }
        }
      });
    } catch ({ message }) {
      NotificationService(NOTIFICATION_TYPE.error, {
        message: t('errors.default'),
        description: message
      });
    }
  }, [saveResult, data?.questionnaire?.results, id, t]);

  return <BoxLoader active={loading}>
    <div className="p-20">
      <Row justify="center" className="mb-20">
        <Col xs={12} sm={8} md={4}>
          <img src={logo} alt="Abiagenten" className="img-fluid" />
        </Col>
      </Row>
      { error ? <AlertError error={error} /> : <Row>
        <Col xs={24} sm={12} className="mb-10">
          <span className="fz-16 text-black mr-5">
            {t('questionnaires.assignee')}:
          </span>
          <span className="fz-16">
            {data?.questionnaire?.creator?.name || '-'}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <div className="mb-10">
            <span className="fz-16 text-black mr-5">
              {t('questionnaires.school')}:
            </span>
            <span className="fz-16">
              {data?.questionnaire?.deal?.school?.name || '-'}
            </span>v
          </div>
          <div className="mb-10">
            <span className="fz-16 text-black mr-5">
              {t('questionnaires.street')}:
            </span>
            <span className="fz-16">
              {data?.questionnaire?.deal?.school?.address?.street || '-'}
            </span>
          </div>
          <div className="mb-10">
            <span className="fz-16 text-black mr-5">
              {t('questionnaires.city')}:
            </span>
            <span className="fz-16">
              {data?.questionnaire?.deal?.school?.address?.city || '-'}
            </span>
          </div>
          <div className="mb-10">
            <span className="fz-16 text-black mr-5">
              {t('questionnaires.state')}:
            </span>
            <span className="fz-16">
              {data?.questionnaire?.deal?.school?.address?.state || '-'}
            </span>
          </div>
        </Col>
      </Row>}
    </div>
    { !survey ? null : <Survey.Survey
      model={ survey }
      onComplete={ onComplete }
    /> }
  </BoxLoader>;
});
