
export const FREE_HEIGHT = (height => {
  const min = 750;
  const header = 64;
  const optimism = height - header;
  return Math.max(optimism, min);
})(window.innerHeight);

export const SERVER_STATUS_TYPE = {
  UP: 'UP',
  DOWN: 'DOWN',
};
