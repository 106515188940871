
// outsource dependencies
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

export const Preloader = ({ active, children }) => !active
  ? children
  : <div className="def-preloader">
    <div id="SPW">
      {_.times(8, item => <div key={item} id={`SP_${item}`} className="sp" />)}
    </div>
  </div>;

Preloader.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.element, PropTypes.node]),
};
Preloader.defaultProps = {
  children: null,
};

export const BoxLoader = props => <Preloader className="box-preloader" style={{ height: '100%' }} {...props} />;

export default Preloader;
