// outsource dependencies
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import React, { memo, useState, useCallback } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// local dependencies
import Survey from './survey';
import config from '../configs/app-config';
import * as ROUTES from '../constants/routes';
import AppContext from '../services/app.context';
import { BoxLoader } from '../components/preloader';
import { SERVER_STATUS_TYPE } from '../constants/specifications';

import Radio from 'antd/lib/radio';
import ConfigProvider from 'antd/lib/config-provider';

// Locales
import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_GB';

import { Health } from '../apollo/api/health.graphql';

const languages = {
  en,
  de,
};

export default memo(() => {
  // NOTE Common data
  const [initialized, setInitialized] = useState(false);
  // NOTE i18n
  const { i18n } = useTranslation();
  const health = useQuery(Health, {
    onCompleted: () => {
      setInitialized(true);
    }
  });

  return <AppContext.Provider
    value={{
      initialized,
      language: i18n.language,
      health: SERVER_STATUS_TYPE.UP === health?.data?.health?.status,
    }}
  >
    <ConfigProvider locale={languages[i18n.language]}>
      <BrowserRouter>
        <Switch>
          <Route path={ROUTES.SURVEY.ROUTE} component={Survey} />
          <Route path="/" component={() => <BoxLoader active />} />
        </Switch>
      </BrowserRouter>
      <Translations />
    </ConfigProvider>
  </AppContext.Provider>;
});

export const Translations = memo(() => {
  // NOTE i18n
  const { i18n } = useTranslation();
  const changeLanguage = useCallback(({ target }) => i18n.changeLanguage(target.value), [i18n]);
  return !config.DEBUG ? null : <Radio.Group value={i18n.language} onChange={changeLanguage} className="position-fixed top-0 right-0 p-20">
    <Radio.Button value="en">English</Radio.Button>
    <Radio.Button value="de">German</Radio.Button>
  </Radio.Group>;
});
