// outsource dependencies
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

// local dependencies
import config from '../configs/app-config';

const httpLink = createHttpLink({
  uri: `${ config.REACT_APP_JAVA_GRAPH_QL_SERVICE }/${ config.REACT_APP_JAVA_GRAPH_QL_PREFIX }/${ config.REACT_APP_JAVA_GRAPH_QL_VERSION }/${ config.REACT_APP_JAVA_GRAPH_QL_APPLICATION }/${config.REACT_APP_JAVA_GRAPH_QL_PATH}`,
});

const authLink = setContext(({ operationName }, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      [config.REACT_APP_GRAPHQL_INTROSPECTIVE_AUTH_HEADER]: `${config.REACT_APP_GRAPHQL_INTROSPECTIVE_AUTH_BASIC_TITLE} ${config.REACT_APP_GRAPHQL_INTROSPECTIVE_AUTH_BASIC_VALUE}`,
    }
  };
});

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  if (networkError) { console.error(`[Network error]: ${networkError}`); }
});

const client = new ApolloClient({
  link: authLink.concat(link).concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
